var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"8"}},[_c('b-card',{attrs:{"title":_vm.$t('Create New Car')}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"form-label-group"},[_c('b-form-input',{staticClass:"mt-2",attrs:{"state":_vm.errors.manufacturer ?false:null,"placeholder":_vm.$t('Manufacturer')},model:{value:(_vm.data.manufacturer),callback:function ($$v) {_vm.$set(_vm.data, "manufacturer", $$v)},expression:"data.manufacturer"}}),(_vm.errors.manufacturer)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.manufacturer[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('Manufacturer')))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"form-label-group"},[_c('b-form-input',{staticClass:"mt-2",attrs:{"state":_vm.errors.model ?false:null,"placeholder":_vm.$t('Model')},model:{value:(_vm.data.model),callback:function ($$v) {_vm.$set(_vm.data, "model", $$v)},expression:"data.model"}}),(_vm.errors.model)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.model[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('Model')))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.wight ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('Wight'),"state":_vm.errors.wight ?false:null},model:{value:(_vm.data.wight),callback:function ($$v) {_vm.$set(_vm.data, "wight", $$v)},expression:"data.wight"}}),(_vm.errors.wight)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.wight[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('Wight')))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.wight1 ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('Wight1'),"state":_vm.errors.wight1 ?false:null},model:{value:(_vm.data.wight1),callback:function ($$v) {_vm.$set(_vm.data, "wight1", $$v)},expression:"data.wight1"}}),(_vm.errors.wight1)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.wight1[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('Wight1')))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.passenger ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('Passenger'),"state":_vm.errors.passenger ?false:null},model:{value:(_vm.data.passenger),callback:function ($$v) {_vm.$set(_vm.data, "passenger", $$v)},expression:"data.passenger"}}),(_vm.errors.passenger)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.passenger[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('Passenger')))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.axle ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('Axle'),"state":_vm.errors.axle ?false:null},model:{value:(_vm.data.axle),callback:function ($$v) {_vm.$set(_vm.data, "axle", $$v)},expression:"data.axle"}}),(_vm.errors.axle)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.axle[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('Axle')))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.cylinder ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('Cylinder'),"state":_vm.errors.cylinder ?false:null},model:{value:(_vm.data.cylinder),callback:function ($$v) {_vm.$set(_vm.data, "cylinder", $$v)},expression:"data.cylinder"}}),(_vm.errors.cylinder)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.cylinder[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('Cylinder')))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"form-label-group"},[_c('b-form-input',{staticClass:"mt-2",attrs:{"state":_vm.errors.motor_type ?false:null,"placeholder":_vm.$t('Motor_type')},model:{value:(_vm.data.motor_type),callback:function ($$v) {_vm.$set(_vm.data, "motor_type", $$v)},expression:"data.motor_type"}}),(_vm.errors.motor_type)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.motor_type[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('Motor_type')))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.motor_power ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('Motor_power'),"state":_vm.errors.motor_power ?false:null},model:{value:(_vm.data.motor_power),callback:function ($$v) {_vm.$set(_vm.data, "motor_power", $$v)},expression:"data.motor_power"}}),(_vm.errors.motor_power)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.motor_power[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('Motor_power')))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.motor_size ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('Motor_size'),"state":_vm.errors.motor_size ?false:null},model:{value:(_vm.data.motor_size),callback:function ($$v) {_vm.$set(_vm.data, "motor_size", $$v)},expression:"data.motor_size"}}),(_vm.errors.motor_size)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.motor_size[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('Motor_size')))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',[_c('label',[_vm._v(_vm._s(_vm.$t('Car_type')))]),_c('b-form-select',{attrs:{"options":_vm.options,"label":"title"},model:{value:(_vm.data.car_type),callback:function ($$v) {_vm.$set(_vm.data, "car_type", $$v)},expression:"data.car_type"}}),(_vm.errors.car_type)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.car_type[0]))]):_vm._e()],1)])],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"w-100 mb-1",attrs:{"variant":"gradient-success"},on:{"click":_vm.create}},[_vm._v(" "+_vm._s(_vm.$t('Create'))+" ")]),_c('b-button',{staticClass:"w-100",attrs:{"variant":"gradient-danger"},on:{"click":function($event){_vm.data={},_vm.errors=[]}}},[_vm._v(" "+_vm._s(_vm.$t('Reset Form'))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }